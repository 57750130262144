const menus = [
  {
    name: "$10,800",
    items: [
      "錦繡三拼盤",
      "蘿蔓蝦鬆",
      "江浙蔥㸆排骨",
      "清蒸海上鮮",
      "鮮香金沙軟絲",
      "乾燒海大蝦",
      "櫻花蝦高麗菜",
      "上海蟹粉菜飯",
      "砂鍋醃篤鮮",
      "精緻甜點",
      "時令水果盤",
    ],
  },
  {
    name: "$12,800",
    items: [
      "晶櫻燴龍蝦雙拼",
      "蟹黃海鮮豆腐煲",
      "御品東坡肉",
      "清蒸海上鮮",
      "紅燒蹄筋烏參",
      "蒜蓉海大蝦",
      "干貝枸杞娃娃菜",
      "上海蟹粉菜飯",
      "帝王蟹米粉煲",
      "精緻甜點",
      "時令水果盤",
    ],
  },
  {
    name: "$16,800",
    items: [
      "澳龍富貴三拼",
      "精選刺身盛合",
      "上海肴豬腳",
      "清蒸深海魚",
      "御品東坡烏參",
      "香烤極地帝王蟹",
      "碧綠河蝦仁",
      "松露蝦仁炒飯",
      "巴西蘑菇雞湯",
      "精緻甜點",
      "時令水果盤",
    ],
  },
  {
    name: "$21,800",
    items: [
      "澳龍富貴四拼",
      "極上刺身盛合",
      "頂級肋眼牛排",
      "極上深海魚",
      "宮廷鮑魚烏參",
      "香烤極地帝王蟹",
      "XO醬干貝蘆筍蝦仁",
      "松露鮭魚卵炒飯",
      "砂鍋火烔雞濃湯",
      "精緻甜點",
      "時令水果盤",
    ],
  },
];

export default menus;
